/* modal.css | Стили модального окна */

.modal-div {
    border: 1px solid #ddd; /* Цвет и стиль рамки */
    padding-top: 10px; /* Отступ внутри сверху */
    padding-right: 10px; /* Отступ внутри справа */
    padding-bottom: 0; /* Отступ внутри снизу */
    padding-left: 10px; /* Отступ внутри слева */
    margin: -10px -10px -10px -10px; /* Отступы снаружи */
    border-radius: 10px; /* Радиус скругления */
    background: transparent; /* Цвет фона */
    width: 100%; /* Ширина */
}

.modal-div-next {
    margin: 20px -10px -10px -10px; /* Отступы снаружи блока */
    width: 100%; /* Ширина */
}

.modal-section-buttons {
    margin: 20px -10px -10px -10px; /* Отступы снаружи блока */
    width: 100%; /* Ширина */
}

.h2-click {
    cursor: pointer; /* Курсор */
    margin-top: -5px;
}

.table-trip {
    font-size: 10px;
}

.table-trip-header {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.table-trip-cell {
    margin: -10px;
    font-size: 12px;
    text-align: center;
}

.table-trip-cell.ant-input-number {
    width: 100%;
}

.table-trip-cell.ant-input-number .ant-input-number-input {
    text-align: center;
}

.table-trip-cell.disabled {
    background: lightgrey;
    opacity: 0.5;
}

.table-trip-cell-left {
    margin: -10px;
    font-size: 12px;
    text-align: left;
}

.table-trip-icon {
    margin: -10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.grid-pl-nts {
    background: #3d4042;
}
