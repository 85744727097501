/*Стили для подсвечивания строк таблицы*/
.table-row-error {
  background-color: #f58181 !important;
}
.table-row-error-split {
  background-color: #f58181 !important;
  color: #0074cc;
  font-weight: bold;
}
.ant-table-tbody
  > .table-row-error:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background-color: #f3c3c3;
}
.table-row-warning {
  background-color: #fcd167 !important;
}
.table-row-warning-split {
  background-color: #fcd167 !important;
  color: #0074cc;
  font-weight: bold;
}
.table-row-split {
  background-color: #87CEEB !important;
  color: #0074cc;
  font-weight: bold;
}
.ant-table-tbody
  > .table-row-warning:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background-color: #faecca;
}

/* Стили для выравнивания содержимого ячеек таблицы*/
.cell-content-to-top .ant-table-row > td {
  vertical-align: top;
}

.write-off-fuel-act-total-row {
  background: #e8e8e8 !important;
}

.write-off-fuel-act-total-row > td {
  font-weight: 600;
  color: #171717;
}

.write-off-fuel-act-total-row > td:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  border-right: none!important;
}
