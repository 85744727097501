@import './reset.css';
@import '../fonts/Roboto/index.css';
@import './antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #3d4042;
  min-width: 1024px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 24px;
  margin-bottom: 0;
}
h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
h3 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #bdbdbd 14%, #d9d9d9 64%);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #d9d9d9 14%, #bdbdbd 64%);
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactVirtualized__Grid {
  outline: none;
}

.primary-background {
  background: rgba(228, 235, 242, 0.3);
}

.link-text {
  text-decoration: underline;
  color: #4370ff;
}

.link-color {
  color: #4370ff;
}

.vertical-align-top {
  vertical-align: top !important;
}

.pointer {
  cursor: pointer;
}
